.about {
  max-width: 1200px;
  margin: 0 auto;
}

.about h2 {
  margin-top: 50px;
}

.about .subheader {
  margin-bottom: 50px;
  margin-top: 50px;
}

.about .subheader h3 {
  font-size: 68px;
  text-wrap: pretty;
  font-weight: 700;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 250px;
}
.about .subheader h3 b {
  color: #D9B100;
}
.about .subheader p {
  text-wrap: pretty;
  max-width: 850px;
  line-height: 2rem;
  font-size: 26px;
  margin: 0 auto;
  text-align: center;
  font-weight: 300;
  margin-top: 30px;
}

.about .subheader .hero-img {
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 18px;
}

.about .subheader .hero-img-1 {
  position: absolute;
  width: 243px;
  height: 242px;
  left: 50%;
  transform: translateX(-50%) translateX(470px);
  top: 110px;
}

.about .subheader .hero-img-2 {
  position: absolute;
  width: 179px;
  height: 180px;
  left: 50%;
  transform: translateX(-50%) translateX(-515px);
  top: 213px;
}

.about .subheader .hero-img-3 {
  position: absolute;
  width: 180px;
  height: 179px;
  left: 50%;
  transform: translateX(-50%) translateX(500px);
  top: 600px;
}

.about .subheader .hero-m-imgs {
  display: none;
}

.about .subheader2 {
  text-align: center;
  text-wrap: pretty;
  max-width: 850px;
  line-height: 3.5rem;
  font-size: 36px;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 150px;
}

.about .vismis > div {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 35px;
  gap: 20px;
  background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%);
  border: 1px solid #EEEEEE;
  border-radius: 8px;
}

.about .vismis > div h4 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 0;
}

.about .vismis > div p {
  font-size: 16px;
  font-weight: 300;
  margin-top: 0;
}

.about .advantages {
  margin-bottom: 140px;
}

.about .advantages h3 {
  font-size: 36px;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 140px;
  margin-bottom: 40px;
  text-align: center;
}

.about .advantages > div > div {
  box-sizing: border-box;
  padding: 35px;
  gap: 20px;
  background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%);
  border: 1px solid #EEEEEE;
  border-radius: 8px;
}

.about .advantages > div > div h4 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;
}

.about .advantages > div > div .card-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.about .advantages > div > div .card-content > div {
  box-sizing: border-box;
  padding: 15px 20px;
  gap: 5px;
  border: 1px solid #D3D9E5;
  border-radius: 8px;
  background: #F6F7F9;
}

.about .advantages > div > div .card-content > div h5 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
  letter-spacing: 0px;
}

.about .advantages > div > div .card-content > div p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
  letter-spacing: 0px;
}





@media only screen and (max-width : 1078px) {
  .about {
    padding: 2rem;
  }

  .about .subheader h3  {
    margin-top: 0px;
    font-size: 42px;
  }
  .about .subheader p {
    font-size: 24px;
  }

  .about .subheader img {
    height: auto !important;
    object-fit: contain;
  }

  .about .subheader .hero-img {
    display: none;
  }

  .about .subheader .hero-m-imgs {
    display: flex;
    gap: 20px;
    height: 150px;
    justify-content: center;
    margin-top: 30px;
  }
}